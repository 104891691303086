import styled, { css } from 'styled-components';

import { media } from './media';

export const DashboardWrapper = styled.div<{
  standalone: boolean;
  showBanner: boolean;
}>`
  ${({ standalone, showBanner }) => css`
    display: grid;
    min-height: 100vh;
    position: relative;
    padding-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
    grid-template-areas:
      'header'
      'content'
      'footer'
      'nav';
    grid-template-rows: ${standalone
      ? '56px auto 222px 56px'
      : '56px auto auto 56px'};
    transition: 200ms transform ease-in-out;

    ${media.portrait} {
      grid-template-areas:
        'nav header'
        'nav content'
        'nav footer';
      grid-template-columns: 200px calc(100vw - 200px);
      grid-template-rows: 56px auto 200px;
    }

    @media (min-width: ${media.large}) {
      grid-template-columns: 240px calc(100vw - 240px);
      grid-template-rows: 56px auto 80px;
    }

    ${showBanner &&
    css`
      grid-template-areas:
        'banner'
        'header'
        'content'
        'footer'
        'nav';

      grid-template-rows: ${standalone
        ? '36px 56px auto 222px 56px'
        : '36px 56px auto auto 56px'};

      ${media.portrait} {
        grid-template-areas:
          'banner banner'
          'nav header'
          'nav content'
          'nav footer';
        grid-template-rows: 36px 56px auto 160px;
      }

      @media (min-width: ${media.large}) {
        grid-template-rows: 36px 56px auto 80px;
      }
    `}
  `}
`;

export const ContentSection = styled.div`
  grid-area: content;
  height: fit-content;

  @media (min-width: ${media.xxsmall}) {
    background-color: unset;
  }
  ${media.portrait} {
    padding-bottom: 20px;
  }
`;
