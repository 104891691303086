import styled, { css } from 'styled-components';

import { media } from '@components/Dashboard/media';

export const Container = styled.div`
  width: 100%;
`;

export const Button = styled.button<{ isFloating: boolean }>`
  ${({ isFloating }) => css`
    float: right;
    background: #0b57d0;
    border: none;
    border-radius: 50%;
    box-shadow: 1px 4px 15px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
    height: 56px;
    width: 56px;

    ${isFloating
      ? `
        position: fixed;
        bottom: 55px;
        right: 20px;
        margin-right: 0px;
        z-index: 20;
      `
      : `
        position: relative;
        right: 0;
      `}

    ${isFloating ? `${media.portrait} { bottom: 20px; }` : ''}
  `}
`;

export const Observer = styled.div`
  width: 100%;
  height: 1px;
`;
