import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { useEthers } from '@usedapp/core';

import { TEST_ACCOUNT } from '@context/constants';
import { useTrackedState } from '@context/store';

import { StyledButton, VerticalDivider } from './styled';

const CreateWalletButton: FunctionComponent = () => {
  const { userDetails } = useTrackedState();

  const { account: ethersAccount } = useEthers();
  const isConnected = !!userDetails || TEST_ACCOUNT || ethersAccount;

  if (isConnected) {
    return null;
  }

  return (
    <>
      <StyledButton
        design="secondary"
        small
        onClick={() => {
          window.open(
            'https://branch.wallet.bitcoin.com/Vwasin63XQb',
            '_blank',
            'noopener,noreferrer',
          );
        }}
      >
        <FormattedMessage id="global.labels.createWallet" />
      </StyledButton>
      <VerticalDivider />
    </>
  );
};

export default CreateWalletButton;
