import styled, { css, keyframes } from 'styled-components';

import {
  colors,
  verseColors,
} from '@bitcoin-portal/verse-web-components/dist/themes/colors';

import verseLogo from '../../../../static/images/uploads/frame1.png';
import verseLogo1 from '../../../../static/images/uploads/frame2.png';
import verseLogo2 from '../../../../static/images/uploads/frame3.png';
import verseLogo3 from '../../../../static/images/uploads/frame4.png';

import Image from '../../Image';
import { media } from '../media';

const logoAnimation = keyframes`
  0%, 100% {
    background-image: url(${verseLogo});
  }
  25% {
    background-image: url(${verseLogo1});
  }
  50% {
    background-image: url(${verseLogo2});
  }
  75% {
    background-image: url(${verseLogo3});
  }
`;

export const HeaderSection = styled.div<{
  hasBottomBorder?: boolean;
  offsetTop?: boolean;
}>`
  grid-area: header;
  position: fixed;
  width: 100%;
  top: ${props => (props.offsetTop ? '36px' : 0)};
  left: 0;
  z-index: 3;
  background-color: ${verseColors.verseDarkViolet};
  border-bottom: 1px solid ${colors.shade40};
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;

  ${media.portrait} {
    ${({ hasBottomBorder }) =>
      !hasBottomBorder &&
      css`
        border: none;
      `}
    background-color: ${verseColors.verseDarkViolet};
    justify-content: flex-end;
  }
`;

export const StyledHeaderIcon = styled(Image)`
  @media (min-width: ${media.medium}) {
    display: none;
  }
`;

export const StyledHeaderLogo = styled(Image)`
  display: none;
  @media (min-width: ${media.small}) {
    display: block;
    margin-right: 5px;
  }
  ${media.portrait} {
    display: none;
  }
`;

export const HeaderBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

interface AnimatedLogoProps {
  width?: string;
  height?: string;
}

export const AnimatedLogo = styled.div<AnimatedLogoProps>`
  display: none;
  @media (min-width: ${media.medium}) {
    display: block;
  }
  ${media.portrait} {
    display: none;
  }
  width: ${({ width }) => width || '240px'};
  height: ${({ height }) => height || '45px'};
  margin: 0.3125rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${verseLogo});
  @supports not (-webkit-overflow-scrolling: touch) {
    animation: ${logoAnimation} 8s infinite linear;
  }
`;
