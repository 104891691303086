import styled from 'styled-components';

import Button from '@bitcoin-portal/verse-web-components/dist/Button';
import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

export const VerticalDivider = styled.div`
  width: 1px;
  background-color: ${colors.verseNavBlue};
  height: 28px;
`;

export const StyledButton = styled(Button)`
  width: 120px;
  gap: 10px;
  letter-spacing: 0%;
`;
