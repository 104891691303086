import { navigate } from 'gatsby';

export const redirect = (chainId: number | undefined, page: any) => {
  if (chainId === 1) {
    navigate(`/${page}/eth/`);
  }
  if (chainId === 137) {
    navigate(`/${page}/matic/`);
  }
  if (chainId === 10000) {
    navigate(`/${page}/sbch/`);
  }
  if (chainId === undefined) {
    navigate(`/${page}/cex/`);
  }
  if (chainId === 11155111) {
    navigate(`/${page}/seth/`);
  }
  if (chainId === 17000) {
    navigate(`/${page}/heth/`);
  }
};
