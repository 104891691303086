import React, { FunctionComponent, useEffect, useMemo } from 'react';

import { useEthers } from '@usedapp/core';
import { UserLiquidityFlat } from '@views/Pools/PoolsWidget/Investments/PairLiquidity';

import { useDexRates } from '@context/providers/DexRatesProvider';
import { useLiquidityPairs } from '@context/providers/LiquidityPairsProvider';

import { findProvider } from '@helpers/index';

import { checkCorrectPairs } from '@views/Pools/helpers/checkCorrectPairs';

import { getSpotRate } from '../api/nekosdk';
import { TEST_ACCOUNT } from '../constants';
import { useDispatch, useTrackedState } from '../store';

interface Props {
  liquidityPosition: ILiquidityPosition;
}

export const FallbackRatesHandler: FunctionComponent<Props> = ({
  liquidityPosition,
}) => {
  const { account: ethersAccount } = useEthers();
  const account = TEST_ACCOUNT || ethersAccount;

  const {
    provider: exchangeProvider,
    fallbackEstimates,
    fallbackRates,
  } = useTrackedState();
  const { liquidityPairs } = useLiquidityPairs();
  const dexRates = useDexRates();

  const dispatch = useDispatch();

  const provider = useMemo(
    () => findProvider(exchangeProvider),
    [exchangeProvider],
  );

  const correctPairs = useMemo(() => {
    if (!liquidityPairs) return null;
    return checkCorrectPairs(liquidityPairs, provider.chainParam || 'eth');
  }, [provider.chainParam, liquidityPairs]);

  const pair = useMemo(
    () => liquidityPairs?.find(p => p.name === liquidityPosition.name),
    [liquidityPairs, liquidityPosition],
  );

  const token0Rate = useMemo(
    () =>
      dexRates?.[exchangeProvider]?.[
        pair?.token0?.symbol?.toUpperCase() || ''
      ] || 0,
    [dexRates, pair, exchangeProvider],
  );

  const token1Rate = useMemo(
    () =>
      dexRates?.[exchangeProvider]?.[
        pair?.token1?.symbol?.toUpperCase() || ''
      ] || 0,
    [dexRates, pair, exchangeProvider],
  );

  const needFallbackRates = useMemo(
    () =>
      !token0Rate || !token1Rate || parseFloat(pair?.reserveUSD || '') < 100,
    [token0Rate, token1Rate, pair?.reserveUSD],
  );

  const totalBalance = UserLiquidityFlat(
    pair!,
    account as string,
    fallbackRates,
    exchangeProvider,
  );

  // set fallback rates
  useEffect(() => {
    const fetchRate = async (symbol: string) => {
      const res = await getSpotRate(symbol);
      const rates = res.getValue();

      return rates?.USD?.rate || 0;
    };

    const calculateFallbackRates = async () => {
      if (pair && correctPairs) {
        let token0FallbackRate = token0Rate;
        let token1FallbackRate = token1Rate;

        // get rate
        token0FallbackRate = await fetchRate(
          pair.token0?.symbol?.toUpperCase(),
        );
        token1FallbackRate = await fetchRate(
          pair.token1?.symbol?.toUpperCase(),
        );

        // set fallback rates
        const rates = fallbackRates;
        if (rates[exchangeProvider]) {
          rates[exchangeProvider][pair.token0?.symbol] = token0FallbackRate;
          rates[exchangeProvider][pair.token1?.symbol] = token1FallbackRate;
        } else {
          const newRatesObj = {
            [pair.token0?.symbol]: token0FallbackRate,
            [pair.token1?.symbol]: token1FallbackRate,
          };
          rates[exchangeProvider] = newRatesObj;
        }

        dispatch({
          type: 'SET_FALLBACK_RATES',
          payload: rates,
        });
      }
    };

    if (needFallbackRates) {
      calculateFallbackRates();
    }
  }, [pair, correctPairs, token0Rate, token1Rate]);

  // set fallback estimates
  useEffect(() => {
    if (
      needFallbackRates &&
      pair &&
      correctPairs &&
      fallbackRates[exchangeProvider]?.[pair?.token0.symbol] !== undefined &&
      fallbackRates[exchangeProvider]?.[pair?.token1.symbol] !== undefined
    ) {
      const estimates = fallbackEstimates;
      estimates[pair.name] = totalBalance;

      dispatch({
        type: 'SET_FALLBACK_ESTIMATES',
        payload: estimates,
      });
    }
  }, [fallbackRates, pair, correctPairs, token0Rate, token1Rate, totalBalance]);

  // unset fallback rates & estimates
  useEffect(() => {
    if (
      !needFallbackRates &&
      pair &&
      fallbackRates[exchangeProvider]?.[pair?.token0.symbol] &&
      fallbackRates[exchangeProvider]?.[pair?.token1.symbol]
    ) {
      // unset rates
      const rates: FallbackRatesType = fallbackRates;
      delete rates[exchangeProvider][pair.token0?.symbol];
      delete rates[exchangeProvider][pair.token1?.symbol];

      dispatch({
        type: 'SET_FALLBACK_RATES',
        payload: rates as DexRatesType,
      });

      // unset estimates
      const estimates = fallbackEstimates;
      delete estimates[pair.name];
      dispatch({
        type: 'SET_FALLBACK_ESTIMATES',
        payload: estimates,
      });
    }
  }, [fallbackRates, pair, token0Rate, token1Rate, totalBalance]);

  return <></>;
};

export default FallbackRatesHandler;
