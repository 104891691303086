import React, { FunctionComponent, useEffect, useState } from 'react';

import { JsonRpcProvider } from '@ethersproject/providers';
import { useLocation } from '@reach/router';
import { useEthers } from '@usedapp/core';
import { AxiosError } from 'axios';

import ChevronDown from '@bitcoin-portal/verse-web-components/dist/Icons/ChevronDown';
import Swap from '@bitcoin-portal/verse-web-components/dist/Icons/Swap';

import { ACTIVE_ENV, PROVIDER_OPTIONS } from '@context/constants';
import { useDispatch, useTrackedState } from '@context/store';
import { TExchangeProvider } from '@context/types';

import { useSwapContext } from '@views/Swap/context/providers/SwapProvider';

import { lookupCexProvider, useQuery } from '@helpers/index';
import { redirect } from '@helpers/redirect';

import { useMultichainProvider } from '@hooks/useMultichainProvider';

import Image from '../Image';
import ProviderSection from './ProviderSection';
import { redirectablePages } from './redirectablePages';
import {
  MenuWrapper,
  ProviderButton,
  ProviderList,
  ProviderModalMenu,
  SwapIconWrapper,
} from './styled';

const ProviderMenu: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { provider } = useTrackedState();
  const { library, deactivate, switchNetwork, account } = useEthers();
  const { cexProvider: cexKey } = useSwapContext();

  const [providerMenuOpen, setProviderMenuOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(0);
  const [cexProvider, setCexProvider] = useState('sideshift');

  const location = useLocation();

  const selectedProviderOption = PROVIDER_OPTIONS[selectedProvider];

  const { icon, label } = useMultichainProvider(selectedProviderOption);

  const testnetEnabled = ['development', 'staging'].includes(ACTIVE_ENV);

  const query = useQuery();
  useEffect(() => {
    const exchangeProvider = query.get('provider') || provider;

    const providerIdx = PROVIDER_OPTIONS.findIndex(
      o => o.provider === exchangeProvider,
    );

    if (providerIdx !== -1) {
      setSelectedProvider(providerIdx);
    }
  }, [provider, query]);

  useEffect(() => {
    if (cexKey) setCexProvider(lookupCexProvider(cexKey));
  }, [cexKey]);

  const onProviderChange = (newProvider: string) => {
    const isMetaMask =
      (library as JsonRpcProvider)?.connection?.url === 'metamask';

    if (isMetaMask) return;

    if (newProvider === 'bitcoincom') {
      deactivate();
    }
  };

  const onProviderSelect = async (prov: IProviderOptionType) => {
    const firstPathSegment = location.pathname.split('/').filter(Boolean)[0];
    try {
      const { provider: selected } = prov;
      if (selected !== provider) {
        dispatch({
          type: 'SET_PROVIDER',
          payload: selected as TExchangeProvider,
        });
      }
      if ((prov.isDeFi || prov.isTestnet) && prov.chain && account) {
        await switchNetwork(prov.chain);
      }
      onProviderChange(selected);
    } catch (e) {
      const err = e as AxiosError;
      console.error('Provider Menu Error', err.message);
      if (
        err.message.includes('wallet_switchEthereumChain') &&
        redirectablePages.includes(firstPathSegment)
      ) {
        const redirectableErrors = [
          'eip155:10000',
          '0x2710',
          'eip155:137',
          'eip155:0',
        ];
        if (redirectableErrors.some(error => err.message.includes(error))) {
          redirect(prov.chain, firstPathSegment);
        }
      }
    }
  };

  return (
    <ProviderModalMenu
      open={providerMenuOpen}
      setOpen={setProviderMenuOpen}
      width={200}
      menuItem={
        <ProviderButton
          design="secondary"
          small
          onClick={() => {
            setProviderMenuOpen(!providerMenuOpen);
          }}
          style={{ gap: '8px' }}
        >
          {selectedProviderOption.icon ? (
            <Image src={icon} width={24} height={24} alt="provider icon" />
          ) : (
            <SwapIconWrapper large>
              <Swap size={16} />
            </SwapIconWrapper>
          )}
          <span>{label}</span>
          <ChevronDown size={12} />
        </ProviderButton>
      }
    >
      <MenuWrapper>
        <ProviderList>
          <ProviderSection
            label="Decentralized Exchanges"
            options={PROVIDER_OPTIONS.filter(prov => !!prov.isDeFi)}
            isCex={false}
            onProviderSelect={onProviderSelect}
          />
          <ProviderSection
            label="Centralized Exchanges"
            options={PROVIDER_OPTIONS.filter(
              prov => !prov.isDeFi && prov.provider === cexProvider,
            )}
            isCex
            onProviderSelect={onProviderSelect}
          />
          {testnetEnabled && (
            <ProviderSection
              label="Test Exchanges"
              options={PROVIDER_OPTIONS.filter(prov => prov.isTestnet)}
              isCex={false}
              onProviderSelect={onProviderSelect}
            />
          )}
        </ProviderList>
      </MenuWrapper>
    </ProviderModalMenu>
  );
};

export default ProviderMenu;
