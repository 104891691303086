import {
  BCH_FLEXUSD_PAIR,
  SEPOLIA_VERSE_ETH_PAIR,
  VERSE_ETH_PAIR_ADDRESS,
} from '@context/constants';

// janky check for correct liquidity pairs based on provider
export const checkCorrectPairs = (
  liquidityPairs: ILiquidityPair[],
  chain: string,
): boolean => {
  const pairNames = liquidityPairs.map(lp => lp.id.toLowerCase());
  const ethPair = VERSE_ETH_PAIR_ADDRESS.toLowerCase();
  const sbchPair = BCH_FLEXUSD_PAIR.toLowerCase();
  const sethPair = SEPOLIA_VERSE_ETH_PAIR.toLowerCase();

  return (
    (chain === 'eth' && pairNames.includes(ethPair)) ||
    (chain === 'sbch' && pairNames.includes(sbchPair)) ||
    (chain === 'seth' && pairNames.includes(sethPair))
  );
};
