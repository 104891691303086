import React, { FunctionComponent } from 'react';

import { useLocation } from '@reach/router';

import { useTrackedState } from '../../../context/store';

import CreateWalletButton from '../../CreateWalletButton';
import LoginButton from '../../LoginButton';
import ProviderMenu from '../../ProviderMenu';
import {
  // ,
  AnimatedLogo,
  HeaderBtnWrapper,
  HeaderSection,
  StyledHeaderIcon,
} from './styled';

const Header: FunctionComponent = () => {
  const { pathname } = useLocation();
  const { isWallet } = useTrackedState();

  const showProviderMenu =
    isWallet === false ||
    ['/earn-bitcoin', '/staking'].some(path => pathname.includes(path));

  return (
    <HeaderSection hasBottomBorder={pathname === '/'} offsetTop={false}>
      <div>
        <StyledHeaderIcon
          height={42}
          width={42}
          src="/icons/verse-icon-512x512.png"
          alt="Verse DEX"
        />
        <AnimatedLogo width="155px" />
      </div>
      <HeaderBtnWrapper>
        <CreateWalletButton />
        {showProviderMenu && <ProviderMenu />}
        <div>
          <LoginButton />
        </div>
      </HeaderBtnWrapper>
    </HeaderSection>
  );
};

export default Header;
