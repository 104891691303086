import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Script } from 'gatsby';

import Image from '@components/Image';

import { useFeatureFlag } from '@helpers/featureFlags';

import { Button, Container, Observer } from './styled';

const CloseIcon = () => {
  return (
    <svg
      id="closeSvg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="white"
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59
    12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

const VerseAIChatBot = () => {
  const { isEnabled } = useFeatureFlag('verseAIChatBot');
  const messengerRef = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const endObserver = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = useCallback(() => {
    if (!messengerRef.current) return;
    const chatToggleBtn =
      messengerRef.current.shadowRoot?.querySelector('button');

    chatToggleBtn?.click();
  }, [messengerRef.current]);

  useEffect(() => {
    const ctrl = new AbortController();

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setIsVisible(entry.isIntersecting);
      });
    });

    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(() => {
        setIsOpen(messengerRef.current?.getAttribute('expand') === '');
      });
    });

    observer.observe(endObserver.current!);
    mutationObserver.observe(messengerRef.current!, {
      attributes: true,
    });

    window.addEventListener(
      'df-messenger-loaded',
      () => {
        const chatToggleBtn =
          messengerRef.current?.shadowRoot?.querySelector('button');

        if (chatToggleBtn) {
          chatToggleBtn.style.display = 'none';
        }
      },
      { signal: ctrl.signal },
    );

    return () => ctrl.abort();
  }, [messengerRef.current, buttonRef.current, endObserver.current]);

  if (!isEnabled) {
    return {
      observer: null,
      button: null,
      script: null,
    };
  }

  return {
    observer: <Observer ref={endObserver} />,
    button: (
      <Button ref={buttonRef} onClick={handleClick} isFloating={!isVisible}>
        {isOpen ? (
          <CloseIcon />
        ) : (
          <Image
            src="/icons/verse-icon-512x512.png"
            alt="Verse Logo"
            width={36}
            height={36}
          />
        )}
      </Button>
    ),
    script: (
      <Container>
        <Script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1" />
        {/* @ts-expect-error web component */}
        <df-messenger
          ref={messengerRef}
          chat-icon="https:&#x2F;&#x2F;analytics.verse.bitcoin.com&#x2F;logo.png"
          intent="WELCOME"
          chat-title="VerseBot"
          agent-id="3b25606a-51fc-4b95-9ecf-5d67e8749a68"
          language-code="en"
        />
      </Container>
    ),
  };
};

export default VerseAIChatBot;
