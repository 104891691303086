import styled from 'styled-components';

import Link from '@bitcoin-portal/verse-web-components/dist/Link';
import { colors } from '@bitcoin-portal/verse-web-components/dist/themes/colors';

import Image from '../../Image';
import { media } from '../media';

export const FooterSection = styled.div`
  grid-area: footer;
  background-color: ${colors.verseDarkViolet};
  padding: 0 24px 24px;
  border-top: 1px solid ${colors.shade30};
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  @media (min-width: ${media.medium}) {
    padding: 24px;
  }
  @media (min-width: ${media.large}) {
    display: grid;
    grid-template-areas:
      'links . logosocial'
      'copywrite . logosocial';
    grid-template-columns: 320px 1fr;
    padding: 24px;
  }
`;

export const FooterLinks = styled.div`
  grid-area: links;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 12px;
  margin: 24px 12px 0 0;
  @media (min-width: ${media.xxsmall}) {
    grid-template-columns: 2fr 1fr;
  }
  @media (min-width: ${media.medium}) {
    grid-template-columns: repeat(4, auto);
    grid-column-gap: 16px;
    margin: 0;
    width: fit-content;
  }
  @media (min-width: ${media.large}) {
    grid-column-gap: 14px;
    margin: 0 0 8px;
  }
`;

export const StyledFooterLink = styled(Link)`
  color: ${colors.shade90};
`;

export const Social = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LogoSocialWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px 0 0;
  margin-bottom: 12px;
  @media (min-width: ${media.xxsmall}) {
    flex-direction: row;
  }
  @media (min-width: ${media.medium}) {
    margin-bottom: 24px;
  }
  @media (min-width: ${media.large}) {
    grid-area: logosocial;
    width: fit-content;
    justify-content: flex-end;
    margin-bottom: 0;
    padding: 0 0 0;
  }
`;

export const LogoWrapper = styled.div`
  padding-right: 16px;
  @media (min-width: ${media.large}) {
    display: none;
  }
`;

export const StyledBcomLogo = styled(Image)`
  height: auto;
  @media (max-width: ${media.xxsmall}) {
    width: 80px;
  }
  @media (max-width: ${media.xsmall}) {
    width: 100px;
  }
  width: 140px;
`;

export const StyledCopyrightText = styled.div`
  max-width: 207px;
  color: ${colors.shade70};
  font-size: 12px;
  line-height: 14.32px;

  @media (min-width: ${media.medium}) {
    max-width: 314px;
  }
`;

export const ChatBotCopyrightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & button {
    margin-left: auto;
  }

  @media (min-width: ${media.xsmall}) {
    justify-content: space-between;
    flex-direction: row;
  }

  @media (min-width: ${media.large}) {
    grid-area: copywrite;

    & button {
      display: none;
    }
  }
`;

export const ChatBotButtonSocial = styled.div`
  display: none;
  margin-left: 8px;

  @media (min-width: ${media.large}) {
    display: block;
  }
`;
