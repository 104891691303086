import { flattenMessages } from '../../helpers';

import enGlobal from '../global/en.json';
import esGlobal from '../global/es.json';
import ruGlobal from '../global/ru.json';
import zhGlobal from '../global/zh.json';

import en from './en.json';
import es from './es.json';
import ru from './ru.json';
import zh from './zh.json';

export default {
  en: {
    ...flattenMessages(en, 'blog'),
    ...flattenMessages(enGlobal, 'global'),
  },
  es: {
    ...flattenMessages(es, 'blog'),
    ...flattenMessages(esGlobal, 'global'),
  },
  ru: {
    ...flattenMessages(ru, 'blog'),
    ...flattenMessages(ruGlobal, 'global'),
  },
  zh: {
    ...flattenMessages(zh, 'blog'),
    ...flattenMessages(zhGlobal, 'global'),
  },
};
