import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useEthers } from '@usedapp/core';
import { Link } from 'gatsby';

import Button from '@bitcoin-portal/verse-web-components/dist/Button';
import External from '@bitcoin-portal/verse-web-components/dist/Icons/External';
import ExternalLink from '@bitcoin-portal/verse-web-components/dist/Link';

import { useVerseClaimMerkle } from '@context/providers/VerseClaimMerkleProvider';
import { useTrackedState } from '@context/store';
import { TMenuItem } from '@context/types';

import { getBlockchain, logAmplitudeEvent } from '@helpers/amplitude';
import { getDisabledPathsList } from '@helpers/featureFlags';
import { getDeviceSize } from '@helpers/index';

import { useWindowSize } from '@hooks/useWindowSize';

import bcomLogo from '../../../../static/images/uploads/bcom-logo.png';
// import verseLogo from '../../../../static/images/uploads/frame1.png';
import { icons } from '../icons';

import Image from '../../Image';
import IntersectionObserverWrapper from './IntersectionObserverWrapper';
import {
  AnimatedLogo,
  BcomLogoWrapper,
  ClaimWrapper,
  ExternalIconWrapper,
  GatsbyLinkWrapper,
  LinkDiv,
  LinkWrapper,
  NavSection,
  NewBadge,
  StyledExternalLink,
  StyledIcon,
  StyledLabel,
  VerseLogoWrapper,
} from './styled';

interface Props {
  page: string;
  standalone: boolean;
}

const Nav: FunctionComponent<Props> = ({ page, standalone }) => {
  const { account } = useEthers();
  const { isWallet, provider } = useTrackedState();
  const merkle = useVerseClaimMerkle();
  const { messages } = useIntl();
  const { width } = useWindowSize();

  const rawLinks = messages['global.navbar.links'] as unknown as TMenuItem[];

  const filteredLinks = useMemo(() => {
    const disabledPaths = getDisabledPathsList();
    const deviceSize = getDeviceSize(width);
    const voteLink = rawLinks.find(link => link.id === 'vote');
    const links = rawLinks.filter(link => {
      if (disabledPaths.includes(link.link)) return false;
      if (link.id === 'claim' && (!merkle || !account)) return false;
      if (link.id === 'vote' && deviceSize === 'sm') return false;
      return true;
    });

    if (
      voteLink &&
      !disabledPaths.includes(voteLink.link) &&
      deviceSize === 'sm'
    ) {
      const index = links.findIndex(link => link.id === 'analytics');
      links.splice(index, 0, voteLink);
    }

    return links;
  }, [merkle, account, width]);

  const trackNavClick = useCallback(
    (label: string, isNew?: boolean) => {
      if (typeof window !== 'undefined') {
        logAmplitudeEvent('navigationClicked', {
          blockchain: getBlockchain(provider),
          'current page': window.location,
          highlight: isNew?.toString() || '',
          menu: 'nav',
          'menu cta': label.toLowerCase(),
        });
      }
    },
    [provider],
  );

  return (
    <NavSection standalone={standalone} offsetTop={false}>
      <VerseLogoWrapper>
        <Link to="/">
          <AnimatedLogo />
        </Link>
      </VerseLogoWrapper>

      <LinkWrapper>
        <IntersectionObserverWrapper
          links={filteredLinks}
          standalone={standalone}
          trackNavClick={trackNavClick}
        >
          {filteredLinks.map(({ link, id, label, isNew }) => {
            const exernalLink = link.includes('http');
            const isButton = ['buy', 'claim'].includes(id);

            const active =
              link === '/' || page.includes('/swap')
                ? page === link
                : link !== '/' && page.includes(link);

            return (
              <LinkDiv
                key={id}
                data-targetid={id}
                onClick={() => trackNavClick(label, isNew)}
              >
                {exernalLink && !isButton && (
                  <StyledExternalLink
                    href={`${link}${isWallet ? '?origin=wallet' : ''}`}
                    newTab={!isWallet}
                    gap={id === 'analytics' ? true : undefined}
                    key={id}
                  >
                    <StyledIcon>{icons[id]}</StyledIcon>
                    <StyledLabel>{label}</StyledLabel>
                    {isNew ? (
                      <NewBadge>New</NewBadge>
                    ) : (
                      <ExternalIconWrapper>
                        <External width={11.25} />
                      </ExternalIconWrapper>
                    )}
                  </StyledExternalLink>
                )}
                {!exernalLink && !isButton && (
                  <GatsbyLinkWrapper
                    key={id}
                    active={active}
                    gap={id === 'swap'}
                  >
                    <Link to={link}>
                      <StyledIcon style={{ top: '2px' }} active={active}>
                        {icons[id]}
                      </StyledIcon>
                      <StyledLabel>{label}</StyledLabel>
                      {isNew && <NewBadge>New</NewBadge>}
                    </Link>
                  </GatsbyLinkWrapper>
                )}
                {exernalLink && isButton && (
                  <ClaimWrapper key={id}>
                    <ExternalLink href={link} newTab>
                      <Button as="div" small fullWidth>
                        {label}
                      </Button>
                    </ExternalLink>
                  </ClaimWrapper>
                )}
                {!exernalLink && isButton && (
                  <ClaimWrapper key={id}>
                    <Link to={link}>
                      <Button as="div" design="secondary" small fullWidth>
                        {label}
                      </Button>
                    </Link>
                  </ClaimWrapper>
                )}
              </LinkDiv>
            );
          })}
        </IntersectionObserverWrapper>
      </LinkWrapper>

      <BcomLogoWrapper>
        <ExternalLink
          href="https://www.bitcoin.com/"
          newTab
          onClick={() => trackNavClick('logo')}
        >
          <Image src={bcomLogo} alt="Bitcoin.com" width={140} height={19} />
        </ExternalLink>
      </BcomLogoWrapper>
    </NavSection>
  );
};

export default Nav;
