import React, { FunctionComponent } from 'react';

import { useLocation } from '@reach/router';

import Swap from '@bitcoin-portal/verse-web-components/dist/Icons/Swap';

import { redirect } from '@helpers/redirect';

import { useMultichainProvider } from '@hooks/useMultichainProvider';

import { redirectablePages } from '../redirectablePages';

import Image from '../../Image';
import { MenuButton, SwapIconWrapper } from './styled';

interface Props {
  prov: IProviderOptionType;
  isCex?: boolean;
  onProviderSelect?: (provider: IProviderOptionType) => void;
}

const Provider: FunctionComponent<Props> = ({
  prov,
  isCex,
  onProviderSelect,
}) => {
  const { icon, label } = useMultichainProvider(prov);
  const location = useLocation();
  const firstPathSegment = location.pathname.split('/').filter(Boolean)[0];

  const handleRedirect = async () => {
    if (redirectablePages.some(page => location.pathname.includes(page))) {
      redirect(prov.chain, firstPathSegment);
    }
  };

  return (
    <MenuButton
      key={prov.provider}
      onClick={() => {
        handleRedirect();
        if (onProviderSelect) {
          onProviderSelect(prov);
        }
      }}
    >
      {isCex ? (
        <SwapIconWrapper>
          <Swap size={12} />
        </SwapIconWrapper>
      ) : (
        <Image src={icon || ''} alt="provider icon" width={18} />
      )}
      {label}
    </MenuButton>
  );
};

export default Provider;
