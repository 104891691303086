exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-collect-fees-tsx": () => import("./../../../src/pages/admin/collect-fees.tsx" /* webpackChunkName: "component---src-pages-admin-collect-fees-tsx" */),
  "component---src-pages-audits-tsx": () => import("./../../../src/pages/audits.tsx" /* webpackChunkName: "component---src-pages-audits-tsx" */),
  "component---src-pages-auth-tsx": () => import("./../../../src/pages/auth.tsx" /* webpackChunkName: "component---src-pages-auth-tsx" */),
  "component---src-pages-burn-tsx": () => import("./../../../src/pages/burn.tsx" /* webpackChunkName: "component---src-pages-burn-tsx" */),
  "component---src-pages-card-index-tsx": () => import("./../../../src/pages/card/index.tsx" /* webpackChunkName: "component---src-pages-card-index-tsx" */),
  "component---src-pages-card-restricted-tsx": () => import("./../../../src/pages/card/restricted.tsx" /* webpackChunkName: "component---src-pages-card-restricted-tsx" */),
  "component---src-pages-claim-tsx": () => import("./../../../src/pages/claim.tsx" /* webpackChunkName: "component---src-pages-claim-tsx" */),
  "component---src-pages-earn-bitcoin-tsx": () => import("./../../../src/pages/earn-bitcoin.tsx" /* webpackChunkName: "component---src-pages-earn-bitcoin-tsx" */),
  "component---src-pages-farms-tsx": () => import("./../../../src/pages/farms.tsx" /* webpackChunkName: "component---src-pages-farms-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lounge-tsx": () => import("./../../../src/pages/lounge.tsx" /* webpackChunkName: "component---src-pages-lounge-tsx" */),
  "component---src-pages-pools-create-tsx": () => import("./../../../src/pages/pools/create.tsx" /* webpackChunkName: "component---src-pages-pools-create-tsx" */),
  "component---src-pages-pools-index-tsx": () => import("./../../../src/pages/pools/index.tsx" /* webpackChunkName: "component---src-pages-pools-index-tsx" */),
  "component---src-pages-staking-tsx": () => import("./../../../src/pages/staking.tsx" /* webpackChunkName: "component---src-pages-staking-tsx" */),
  "component---src-pages-swap-tsx": () => import("./../../../src/pages/swap.tsx" /* webpackChunkName: "component---src-pages-swap-tsx" */),
  "component---src-pages-swap-v-2-tsx": () => import("./../../../src/pages/swap/v2.tsx" /* webpackChunkName: "component---src-pages-swap-v-2-tsx" */),
  "component---src-pages-vote-tsx": () => import("./../../../src/pages/vote.tsx" /* webpackChunkName: "component---src-pages-vote-tsx" */),
  "component---src-views-blog-template-tsx": () => import("./../../../src/views/Blog/Template.tsx" /* webpackChunkName: "component---src-views-blog-template-tsx" */)
}

